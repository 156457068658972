.wrapper {
  width: 80%;
  margin: 0 auto;
}

@font-face {
  font-family: "source_regular";
  src: url(../fonts/SourceSerif4_18pt-Regular.woff);
}

@font-face {
  font-family: "source_light";
  src: url(../fonts/SourceSerif4_18pt-Light.woff);
}

@font-face {
  font-family: "source_bold";
  src: url(../fonts/SourceSerif4_18pt-Bold.woff);
}

@font-face {
  font-family: "source_medium";
  src: url(../fonts/SourceSerif4_18pt-Medium.woff);
}

img {
  display: block;
  width: 100%;
}
:root {
  --brown: #5c260c;
  --pink: #f482b1;
}
body {
  font-family: "source_regular";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

input::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  display: none;
}
